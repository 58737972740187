import type { JSX } from "react";

import type { FeaturePageComponent, FeaturePageWithProps } from "./page.model";
import { debugPage } from "./page.utils";
import { PageErrorBoundary } from "./PageErrorBoundary";
import { PageSEO } from "./PageSEO";

export function withPage<
  P extends JSX.IntrinsicAttributes = Record<string, unknown>
>(PageComponent: FeaturePageComponent<P>) {
  const meta = PageComponent?.meta || undefined;

  const WithPage: FeaturePageWithProps<P> = (props) => {
    debugPage.debug("Enriching...");
    return (
      <PageErrorBoundary>
        {meta && <PageSEO {...meta} />}
        <PageComponent {...props} />
      </PageErrorBoundary>
    );
  };
  WithPage.getLayout = PageComponent?.PageLayout?.getLayout || ((page) => page);
  WithPage.pageProps = {
    displayLoader: PageComponent.displayLoader ?? true,
    requiredPermissions: []
  };

  return WithPage;
}
