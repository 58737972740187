import { jsx as d } from "react/jsx-runtime";
import { styled as e } from "@linaria/react";
import m from "clsx";
import { forwardRef as f } from "react";
/* empty css                        */
const c = /* @__PURE__ */ e("article")({
  name: "SModalBody",
  class: "SModalBody_s18933ss",
  propsAsIs: !1
}), u = f(function({
  className: o,
  children: s,
  mqDisableGutters: r = !1,
  mqAs: a = "article",
  ...t
}, l) {
  return /* @__PURE__ */ d(c, {
    ...t,
    as: a,
    ref: l,
    className: m(o, {
      "no-gutters": r
    }),
    children: s
  });
});
export {
  u as ModalBody,
  c as SModalBody
};
