import { makePx as e } from "./theme.util.makePx.js";
const t = 0, n = e(576), o = e(577), a = e(991), s = e(992), d = (i) => {
  switch (i) {
    case "mobile-only":
      return `@media only screen and (max-width: ${n})`;
    case "tablet-only":
      return `@media only screen and (min-width: ${o}) and (max-width: ${a})`;
    case "desktop-only":
    case "desktop-up":
      return `@media only screen and (min-width: ${s})`;
    case "mobile-up":
      return `@media only screen and (min-width: ${t})`;
    case "tablet-up":
    case "tablet-to-desktop":
      return `@media only screen and (min-width: ${o})`;
    case "mobile-to-tablet":
      return `@media only screen and (min-width: ${t}) and (max-width: ${n})`;
    case "mobile-to-desktop":
      return `@media only screen and (min-width: ${t}) and (max-width: ${a})`;
    default:
      return "THIS IS NOT A VALUE";
  }
}, l = d("mobile-to-desktop"), r = d("desktop-up");
export {
  r as desktopStyles,
  d as makeResponsiveMedia,
  l as mobileStyles
};
