import { jsx as i } from "react/jsx-runtime";
import { makeRem as p } from "@machineq/theme";
import R from "clsx";
import s, { useRef as C } from "react";
import { ButtonChildren as c } from "./ButtonChildren.js";
import { useCombinedRefs as S } from "../../hooks/hook.useCombinedRef.js";
/* empty css                     */
const g = "SButtonCSS_s2gliwi", k = (r, o, n, t, e) => R(r, g, o, n, t, {
  loading: e
}), f = ({
  mqVariant: r,
  mqColor: o,
  mqSize: n = "default",
  className: t,
  children: e,
  loading: u = !1,
  style: a,
  disabled: l,
  setWidth: w,
  ...B
}, b) => {
  const d = C(null), m = u && d.current ? d.current.offsetWidth : void 0, h = S(b, d);
  return {
    ...B,
    disabled: l || u,
    ref: h,
    className: k(t, r, n, o, u),
    style: {
      width: m ? p(m) : void 0,
      ...a
    }
  };
}, v = s.forwardRef(function({
  type: o = "button",
  children: n,
  ...t
}, e) {
  const u = f(t, e);
  return /* @__PURE__ */ i("button", {
    type: o,
    ...u,
    ref: e,
    children: /* @__PURE__ */ i(c, {
      loading: t.loading,
      children: n
    })
  });
}), y = s.forwardRef(function({
  children: o,
  ...n
}, t) {
  const e = f(n, t);
  return /* @__PURE__ */ i("div", {
    ...e,
    ref: t,
    children: /* @__PURE__ */ i(c, {
      loading: n.loading,
      children: o
    })
  });
}), z = s.forwardRef(function({
  children: o,
  ...n
}, t) {
  const e = f(n, t);
  return /* @__PURE__ */ i("a", {
    ...e,
    ref: t,
    children: /* @__PURE__ */ i(c, {
      loading: n.loading,
      children: o
    })
  });
});
export {
  v as Button,
  y as ButtonContent,
  z as ButtonLink
};
