import { jsx as r, Fragment as s } from "react/jsx-runtime";
import { styled as t } from "@linaria/react";
import { Progress as e } from "@machineq/icons";
import { Icon as m } from "../icon/Icon.js";
/* empty css                             */
const n = /* @__PURE__ */ t("div")({
  name: "SDiv",
  class: "SDiv_s1v2s7so",
  propsAsIs: !1
}), a = ({
  children: o,
  loading: i
}) => i ? /* @__PURE__ */ r(n, {
  children: /* @__PURE__ */ r(m, {
    mqSize: 16,
    mqAccessibility: "decorative",
    Icon: e
  })
}) : /* @__PURE__ */ r(s, {
  children: o
});
export {
  a as ButtonChildren
};
