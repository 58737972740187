import { jsx as i } from "react/jsx-runtime";
import { styled as e } from "@linaria/react";
import r from "clsx";
import { forwardRef as a } from "react";
/* empty css                                */
const H = /* @__PURE__ */ e("h1")({
  name: "SHeading",
  class: "SHeading_sekgua9",
  propsAsIs: !1
}), C = "CSS_H1_c1n26pyx", p = "CSS_H2_c1w6igcu", h = "CSS_H3_cp2oios", l = "CSS_H4_csjhcr4", f = "CSS_H5_c1v9wh3d", m = "CSS_H6_c1t3wb8y", g = "CSS_STitle1_c1n2s0oc", d = "CSS_STitle2_c1f2wjf7", j = a(function({
  mqNode: t = "h1",
  mqVariant: s,
  className: o,
  children: c,
  ..._
}, n) {
  const S = s || t;
  return /* @__PURE__ */ i(H, {
    ..._,
    ref: n,
    as: t,
    className: r(o, {
      [C]: S === "h1",
      [p]: S === "h2",
      [h]: S === "h3",
      [l]: S === "h4",
      [f]: S === "h5",
      [m]: S === "h6",
      [g]: S === "subtitle1",
      [d]: S === "subtitle2"
    }),
    children: c
  });
});
export {
  j as TypographyHeading
};
