import { jsx as t } from "react/jsx-runtime";
import { styled as i } from "@linaria/react";
import e from "clsx";
import { forwardRef as f, memo as n } from "react";
/* empty css                             */
const C = /* @__PURE__ */ i("div")({
  name: "STypographyCopy",
  class: "STypographyCopy_s1d3qld9",
  propsAsIs: !1
}), c = f(function({
  mqNode: o = "div",
  mqVariant: p = "body1",
  mqColor: r = "textPrimary",
  className: y,
  children: a,
  ...s
}, m) {
  return /* @__PURE__ */ t(C, {
    ...s,
    ref: m,
    as: o,
    className: e(y, p, r),
    children: a
  });
}), S = n(c);
export {
  S as TypographyCopy
};
