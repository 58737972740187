import { styled as o } from "@linaria/react";
/* empty css                          */
const t = /* @__PURE__ */ o("footer")({
  name: "ModalFooter",
  class: "ModalFooter_m1ephq6a",
  propsAsIs: !1
});
export {
  t as ModalFooter
};
