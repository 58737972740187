import { jsx as m } from "react/jsx-runtime";
import { makeRem as t } from "@machineq/theme";
import { forwardRef as d } from "react";
import { TypographyHeading as i } from "../../../1_atoms/typography/TypographyHeading.js";
const s = d(function({ children: r, style: o, ...e }, a) {
  return /* @__PURE__ */ m(
    i,
    {
      mqVariant: "h5",
      mqNode: "h1",
      ...e,
      ref: a,
      style: {
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        gap: t(16),
        ...o
      },
      children: r
    }
  );
});
export {
  s as ModalHeaderText
};
