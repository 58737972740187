import { styled as e } from "@linaria/react";
/* empty css                          */
const r = /* @__PURE__ */ e("header")({
  name: "ModalHeader",
  class: "ModalHeader_m10h6web",
  propsAsIs: !1
});
export {
  r as ModalHeader
};
